import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WebsocketService } from '@services/web-socket.service';

@Component({
	selector: 'mi-root',
	standalone: true,
	imports: [RouterOutlet],
	template: `<main class="app-container">
		<router-outlet></router-outlet>
	</main>`
})
export class AppComponent implements OnInit {
	private ws = inject(WebsocketService);

	ngOnInit() {
		this.ws.connect();
	}
}
