import { Component, OnInit, inject } from '@angular/core';
import { GqlServerService } from '@services/gql-server.service';
import { gql } from 'apollo-angular';
import { AllSections, HelpSection } from './help-center.constants';
import { HelpColumnComponent } from './help-column/help-column.component';

export const HelpCenterQuery = gql`
	{
		getUserGuideUrl {
			guideUrl
		}
	}
`;

@Component({
	selector: 'mi-help-center',
	standalone: true,
	imports: [HelpColumnComponent],
	templateUrl: './help-center.component.html',
	styleUrl: './help-center.component.scss'
})
export class HelpCenterComponent implements OnInit {
	sections = AllSections;
	pdfUrl: string | null = null;

	private gql = inject(GqlServerService).main;

	guideUrl: string | null = null;

	ngOnInit(): void {
		this.gql
			.query({
				query: HelpCenterQuery
			})
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.subscribe((c: any) => {
				this.pdfUrl = c.data.getUserGuideUrl.guideUrl;
			});
	}

	getSectionsForColumn(columnId: string): HelpSection[] {
		return this.sections.filter(section => section.columnId === columnId);
	}

	openPdfPage(page: number) {
		window.open(`${this.pdfUrl}#page=${page}`, '_blank');
	}
}
