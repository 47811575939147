interface HelpLink {
	linkTitle: string;
	page: number;
}

export interface HelpSection {
	id: string;
	columnId: string;
	sectionTitle: string;
	links: HelpLink[];
}

const GettingStartedSection: HelpSection = {
	id: 'getting-started',
	columnId: 'column-1',
	sectionTitle: 'Getting Started',
	links: [
		{ linkTitle: 'System Abbreviations', page: 1 },
		{ linkTitle: 'System navigation', page: 3 },
		{ linkTitle: 'Upload a file', page: 5 },
		{ linkTitle: 'Search by JPN/MPN', page: 7 },
		{ linkTitle: 'Start a new quote', page: 9 }
	]
};

const WorkingWithQuotePartsTableSection: HelpSection = {
	id: 'quote-parts-table',
	columnId: 'column-1',
	sectionTitle: 'Working with the Quote Parts Table',
	links: [
		{ linkTitle: 'What is Quote Parts Table?', page: 10 },
		{ linkTitle: 'Columns & Filtration Information', page: 12 },
		{ linkTitle: 'Adding a Part(s)', page: 22 },
		{ linkTitle: 'Bulk Assign Region', page: 23 },
		{ linkTitle: 'Bulk Assign Quantity', page: 24 }
	]
};

const WorkingWithQuoteListTableSection: HelpSection = {
	id: 'quote-list-table',
	columnId: 'column-2',
	sectionTitle: 'Working with the Quote List Table',
	links: [
		{ linkTitle: 'What is Quote List Table?', page: 25 },
		{ linkTitle: 'Columns & Filtration Information', page: 26 },
		{ linkTitle: 'Search Quote', page: 38 }
	]
};

const QuoteGeneralInformationSection: HelpSection = {
	id: 'quote-general-information',
	columnId: 'column-2',
	sectionTitle: 'Quote General Information',
	links: [{ linkTitle: 'Quote General Information Section', page: 39 }]
};

const ShouldCostAnalysisSection: HelpSection = {
	id: 'should-cost',
	columnId: 'column-3',
	sectionTitle: 'Should Cost Analysis (SCA)',
	links: [
		{ linkTitle: 'Should Cost Analysis (SCA) Result Section', page: 40 },
		{ linkTitle: 'Running Should Cost Analysis', page: 41 },
		{ linkTitle: 'Should Cost Analysis (SCA) Confidence Percentage & Range', page: 42 }
	]
};

const PartConfigurationSection: HelpSection = {
	id: 'part-configuration',
	columnId: 'column-3',
	sectionTitle: 'Part Configuration',
	links: [{ linkTitle: 'Part Attributes Section', page: 44 }]
};

const SimilarPartsSection: HelpSection = {
	id: 'similar-parts',
	columnId: 'column-3',
	sectionTitle: 'Similar Parts',
	links: [
		{ linkTitle: 'Similar Parts Section', page: 45 },
		{ linkTitle: 'Working with Similar Parts Table', page: 46 }
	]
};

const SuppliersSection: HelpSection = {
	id: 'suppliers',
	columnId: 'column-4',
	sectionTitle: 'Suppliers',
	links: [
		{ linkTitle: 'Suppliers Section', page: 50 },
		{ linkTitle: 'Suggested Suppliers ', page: 48 }
	]
};

const DigitalFactoryEstimatesSection: HelpSection = {
	id: 'dfe',
	columnId: 'column-4',
	sectionTitle: 'Digital Factory Estimates',
	links: [
		{ linkTitle: 'What are Digital Factory Estimates?', page: 51 },
		{ linkTitle: 'Request an Estimate', page: 53 },
		{ linkTitle: 'Adding a Manual Estimate', page: 54 }
	]
};

const CollaborationSection: HelpSection = {
	id: 'dfe',
	columnId: 'column-4',
	sectionTitle: 'Collaboration',
	links: [
		{ linkTitle: 'Inviting an Owner ', page: 55 },
		{ linkTitle: 'Inviting a Contributor', page: 56 }
	]
};

export const AllSections: HelpSection[] = [
	GettingStartedSection,
	WorkingWithQuotePartsTableSection,
	WorkingWithQuoteListTableSection,
	QuoteGeneralInformationSection,
	ShouldCostAnalysisSection,
	PartConfigurationSection,
	SimilarPartsSection,
	SuppliersSection,
	DigitalFactoryEstimatesSection,
	CollaborationSection
];
