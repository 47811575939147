import { ICommonCoreState, JblCommonCoreInitialState, JblCommonCoreReducer } from '@jbl-pip/core-state';
import { Action, createReducer } from '@ngrx/store';

export const CoreInitialState: ICommonCoreState = {
	...JblCommonCoreInitialState
};

const createCoreReducer = createReducer(CoreInitialState, ...JblCommonCoreReducer());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CoreReducer = (state: ICommonCoreState, action: Action): any => createCoreReducer(state, action);
