import { Routes } from '@angular/router';
import { HelpCenterComponent } from '@components/help-center/help-center.component';
import { MiRoutes } from '@core/configs/routes';
import { AdminGuard } from '@core/guards/admin.quard';
import { AuthGuard } from '@core/guards/auth.guard';
import { QuoteGuard } from '@core/guards/quote.guard';
import { SignoutGuard } from '@core/guards/signout.guard';
import { JblNotFoundComponent, JblNotPermittedComponent } from '@jbl-pip/core';
import { MainLayoutComponent } from './shared/components/app-layout/main-layout.component';

export const routes: Routes = [
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: MiRoutes.dashboard.url, pathMatch: 'full' },
			{
				path: MiRoutes.dashboard.path,
				loadComponent: () => import('./features/dashboard/dashboard.component').then(module => module.DashboardComponent)
			},
			{
				path: MiRoutes.quotes.path,
				canActivate: [QuoteGuard],
				loadChildren: () => import('./features/quotes/quotes.routes').then(routes => routes.QuotesRoutes)
			},
			{
				path: MiRoutes.helpCenter.path,
				component: HelpCenterComponent
			},
			{
				path: MiRoutes.admin.path,
				canActivate: [AdminGuard],
				loadChildren: () => import('./features/admin/admin.routes').then(routes => routes.AdminRoutes)
			}
		]
	},
	{
		path: MiRoutes.login.path,
		loadChildren: () => import('./features/login/login.module').then(module => module.LoginModule),
		canActivate: [SignoutGuard]
	},
	{
		path: MiRoutes.noPermissions.path,
		component: JblNotPermittedComponent
	},
	{
		path: '**',
		component: JblNotFoundComponent
	}
];
