import { AdminPanelActions } from '@core/services/cerbos/cerbos.constants';
import { JblUserOption } from '@jbl-pip/core';

export const UserOptions = [
	{
		id: 'feedback',
		title: 'Feedback',
		icon: 'comment',
		iconType: 'solid'
	},
	{
		id: 'help',
		title: 'Help',
		icon: 'questionCircle',
		iconType: 'solid'
	},
	{
		id: 'admin',
		title: 'Admin Panel',
		icon: 'gear',
		cerbosAction: AdminPanelActions.Access
	}
] as JblUserOption[];
