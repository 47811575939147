import { JsonPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { startWith } from 'rxjs';

interface MappedUser {
	fullName: string;
	email: string;
	username: string;
}

@Component({
	selector: 'mi-invite-contributors-modal',
	standalone: true,
	imports: [JsonPipe, ReactiveFormsModule, FormsModule],
	templateUrl: './invite-contributors-modal.component.html',
	styleUrl: './invite-contributors-modal.component.scss'
})
export class InviteContributorsModalComponent implements OnInit {
	usersList = [] as MappedUser[];
	visibleUsersList = [] as MappedUser[];

	alreadyOwnersIds = [] as string[];
	form!: FormGroup;
	searchControl = new FormControl(null);
	idToEmailMap = {} as Record<string, string>;

	public activeModal = inject(NgbActiveModal);
	private fb = inject(FormBuilder);

	ngOnInit() {
		this.initForm();
	}

	private initForm(): void {
		const group = {} as Record<string, boolean>;
		const idToEmailMap = {} as Record<string, string>;

		this.usersList.forEach(user => {
			group[user.username] = false;
			idToEmailMap[user.username] = user.email;
		});

		this.searchControl.valueChanges.pipe(startWith('')).subscribe((value: string | null) => {
			const query = (value ?? '').trim().toLowerCase();

			this.visibleUsersList = query.length
				? this.usersList.filter((user: MappedUser) => user.fullName.toLowerCase().includes(query))
				: this.usersList;
		});

		this.form = this.fb.group(group);
		this.idToEmailMap = idToEmailMap;
	}

	dismiss(): void {
		this.activeModal.close(null);
	}

	inviteSelectedContributors() {
		const selecetedUsersData = Object.entries(this.form.getRawValue())
			.filter(([, selected]) => selected)
			.map(([userId]) => ({ userId, userEmail: this.idToEmailMap[userId] }));

		this.activeModal.close(selecetedUsersData);
	}

	get isFormInvalid(): boolean {
		return Object.values(this.form.getRawValue()).some(selected => selected);
	}
}
