<div class="sub-header">
	<nav class="sub-navbar">
		<a routerLink="/mqa" routerLinkActive="active">{{ currentRole() }}</a>
		<div class="vertical-line"></div>

		@if (!isClosed() && showInviteButtons()) {
			<button *ifAllowOwnersInvites class="invite-btn" (click)="openInviteOwnersModal()">Invite Owner</button>
			<button *ifAllowContributorsInvites class="invite-btn" (click)="openInviteContributorsModal()">Invite Contributor</button>
		}
	</nav>
</div>
