import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { BearerTokenInterceptor } from '@core/interceptors/bearer-token.interceptor';
import { InitService } from '@core/services/init.service';
import { JblNotFoundModule, JblNotPermittedModule } from '@jbl-pip/core';
import { JblIconModule, JblThemeModule } from '@jbl/foundation';

export function provideInitializers() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [InitService],
			useFactory: (initService: InitService) => initService.initializeApp()
		}
	];
}

export function provideInterceptors() {
	return [{ provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true }];
}

export function provideCommonJbl() {
	return importProvidersFrom([
		JblThemeModule,
		JblIconModule,
		JblNotFoundModule.forRoot({
			subtitle: 'It seems like this page is not exist in this app or it was removed',
			button: 'Return to the Homepage',
			img: 'assets/img/various/no-data.png'
		}),
		JblNotPermittedModule.forRoot({
			subtitle: 'It seems like this page is not allowed for your account. To proceed with it, please request the access.',
			img: 'assets/img/various/shield.svg'
		})
	]);
}
