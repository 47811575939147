<div class="help-column" [id]="columnId">
	@for (section of sections; track section.id) {
		<div class="help-section" [id]="section.id">
			<h2 class="section-title">{{ section.sectionTitle }}</h2>
			<ul class="link-list">
				@for (link of section.links; track $index) {
					<li class="link-item">
						<a (click)="openPdfPage(link.page)" class="help-link">
							<img src="assets/img/various/pdf-icon.png" alt="PDF" class="pdf-icon" />
							{{ link.linkTitle }}
						</a>
					</li>
				}
			</ul>
		</div>
	}
</div>
