import { gql } from 'apollo-angular';

export const AppSecurityUsersQuery = gql`
	query GetQuoteModuleUserList {
		getQuoteModuleUserList {
			email
			firstname
			lastname
			roles
			username
			ownership {
				owns
				contributes
			}
		}
	}
`;
