import { Injectable, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { AppSecurityUser } from '@core/services/app-security.service';
import { CerbosResource, QuoteActions } from '@core/services/cerbos/cerbos.constants';
import { CerbosValidationRequest } from '@core/services/cerbos/cerbos.interface';
import { CerbosService } from '@core/services/cerbos/cerbos.service';
import { ConfigService } from '@core/services/config.service';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

function extractQuoteId(url: string): string {
	// Use a regular expression to match the quote ID pattern
	const match = url.match(/\/quotes\/(\w+)/);

	// If a match is found, return the captured group (quote ID)
	// Otherwise, return null
	return match ? match[1] : 'no-quote-id';
}

@Injectable({
	providedIn: 'root'
})
export class QuoteGuardService {
	private router = inject(Router);
	private cerbosService = inject(CerbosService);
	private configService = inject(ConfigService);

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		const user$ = toObservable(this.configService.currentUser).pipe(filter(user => !!user));
		const quoteId$ = of(extractQuoteId(state.url));

		return combineLatest([user$, quoteId$]).pipe(
			tap(c => console.log(c)),
			map(([user, quoteId]) => this.createCerbosRequest(user as AppSecurityUser, quoteId)),
			map(cerbosRequest => this.cerbosService.checkResource(cerbosRequest)),
			map(decision => decision.isAllowed(QuoteActions.View)),
			tap(allowed => {
				if (!allowed) {
					this.router.navigateByUrl(MiRoutes.noPermissions.url);
				}
			})
		);
	}

	private createCerbosRequest(user: AppSecurityUser, quoteId: string): CerbosValidationRequest {
		return {
			principal: {
				id: user?.username,
				roles: user?.roles,
				attr: {
					ownership: user?.ownership
				}
			},
			resource: {
				kind: CerbosResource.Quote,
				attr: { quoteId }
			}
		};
	}
}

export const QuoteGuard: CanActivateFn = (r, s): Observable<boolean | UrlTree> => inject(QuoteGuardService).canActivate(r, s);
