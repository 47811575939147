import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import { environment } from '@environment';
// import 'ag-grid-enterprise';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { registerGridModules } from 'config/grid-config';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

registerGridModules();

bootstrapApplication(AppComponent, appConfig)
	.then(() => console.info('[bootstrapApplication] App bootstrapped'))
	.catch(err => console.error(err));
