import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, output } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { UserOptions } from '@core/configs/user-options';
import { CerbosFilterListPipe } from '@core/services/cerbos/cerbos-filter-list.pipe';
import { environment } from '@environment';
import { JblHeaderModule, JblProfileModule, JblToastModule, JblToastService } from '@jbl-pip/core';
import { CommonCoreSelectors } from '@jbl-pip/core-state';
import { JblIconModule } from '@jbl/foundation';
import { Store } from '@ngrx/store';
import { QuotesStore } from '@stores/quotes.store';
import { NavSubHeaderComponent } from '../nav-sub-header/nav-sub-header.component';

const common = [RouterOutlet, NgIf, NgForOf, AsyncPipe];
const jbl = [JblHeaderModule, JblProfileModule, JblIconModule, JblToastModule];

@Component({
	selector: 'mi-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	standalone: true,
	imports: [...common, ...jbl, NavSubHeaderComponent, CerbosFilterListPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent {
	logo = environment.logo.imagePath;
	userOptions = UserOptions;
	user$ = inject(Store).select(CommonCoreSelectors.selectUser);

	toastService = inject(JblToastService);
	onLinkClicked = output<void>();
	store = inject(QuotesStore);
	private router = inject(Router);

	@Input()
	public showShadow = false;

	onOptionClick(optionId: string) {
		switch (optionId) {
			case 'feedback':
				this.store.openFeedbackModal();
				break;
			case 'help':
				this.router.navigate([MiRoutes.helpCenter.url]);
				break;
			case 'admin':
				this.router.navigate([MiRoutes.admin.url]);
				break;
		}
	}

	readonly links = [
		// {
		// 	id: 'dashboard',
		// 	title: 'Dashboard',
		// 	url: '/dashboard',
		// 	active: true
		// },
		{
			id: 'my-quotes',
			title: 'My Quotes',
			url: '/dashboard',
			active: true
		}
	];

	onClick() {
		this.onLinkClicked.emit();
	}
}
