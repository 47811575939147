import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CoreReducer } from '@core/state/core.reducer';
import { CORE_FEATURE_KEY } from '@jbl-pip/core-state';
import { provideStore } from '@ngrx/store';
import { ApolloModule } from 'apollo-angular';
import { provideCommonJbl, provideInitializers, provideInterceptors } from './app.providers';
import { routes } from './app.routes';

// Required for jbl auth service usage
const appState = {
	[CORE_FEATURE_KEY]: CoreReducer
};

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(ApolloModule),
		...provideInitializers(),
		provideRouter(routes),
		provideCommonJbl(),
		provideAnimations(),
		provideStore(appState),
		...provideInterceptors(),
		provideHttpClient(withInterceptorsFromDi()),
		DecimalPipe
	]
};
