import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { AppSecurityUsersQuery } from '@graphql/user.queries';
import { GqlServerService } from '@services/gql-server.service';
import { Observable, catchError, map, of } from 'rxjs';
import { CerbosRole } from './cerbos/cerbos.constants';

export interface GerUserInfoResponse {
	getQuoteModuleUserList: AppSecurityUser[];
}

export interface AppSecurityUser {
	email: string;
	fullName?: string;
	firstname: string;
	lastname: string;
	roles: CerbosRole[];
	role: string; // roles[0]
	username: string;
	ownership: {
		owns: string[];
		contributes: string[];
	};
}

export type OwnershipKey = 'owns' | 'contributes';

export interface LocalOwnerShipUpdatePayload {
	quoteId: string;
	userIds: string[];
	ownershipKey: 'owns' | 'contributes';
}

const RolesOrder = [CerbosRole.SystemAdmin, CerbosRole.GCM, CerbosRole.SCP, CerbosRole.MCET, CerbosRole.BU, CerbosRole.BusinessAdmin];

@Injectable({
	providedIn: 'root'
})
export class AppSecurityService {
	private gql = inject(GqlServerService).main;

	fetchAppSecurityUsers(): Observable<AppSecurityUser[]> {
		return this.gql
			.query<GerUserInfoResponse>({
				query: AppSecurityUsersQuery
			})
			.pipe(
				map((res: ApolloQueryResult<GerUserInfoResponse>) => res.data.getQuoteModuleUserList),
				catchError(() => of([])),
				map((appSecurityUsers: AppSecurityUser[]) =>
					appSecurityUsers.map((user: AppSecurityUser) => {
						const sortedRoles = [...user.roles].sort((a, b) => RolesOrder.indexOf(a) - RolesOrder.indexOf(b));

						return {
							...user,
							fullName: `${user.firstname} ${user.lastname}`,
							role: sortedRoles[0] ?? 'Unknown role'
						};
					})
				)
			);
	}
}
